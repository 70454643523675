
import {defineComponent, ref} from "vue";
import {translateMixin} from "@/i18n";

import * as beastsStore from "../store/beastsStore"

export default defineComponent({
  mixins: [
    translateMixin,
  ],
  setup() {
    const deleteId = ref<string | null>(null)

    return {
      beastList: beastsStore.state.beastList,

      breedingStationName: beastsStore.getters.breedingStationName,

      deleteId,
      deleteBeast(id: string) {
        if (deleteId.value !== id) {
          deleteId.value = id
          return
        }
        beastsStore.actions.deleteBeast(id)
      },
    }
  },
})
